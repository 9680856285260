/*! modernizr 3.5.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-videoautoplay-setclasses !*/
!(function (A, e, o) {
  function t(A, e) {
    return typeof A === e;
  }
  function n() {
    var A, e, o, n, a, l, i;
    for (var r in s)
      if (s.hasOwnProperty(r)) {
        if (
          ((A = []),
          (e = s[r]),
          e.name &&
            (A.push(e.name.toLowerCase()),
            e.options && e.options.aliases && e.options.aliases.length))
        )
          for (o = 0; o < e.options.aliases.length; o++)
            A.push(e.options.aliases[o].toLowerCase());
        for (n = t(e.fn, 'function') ? e.fn() : e.fn, a = 0; a < A.length; a++)
          (l = A[a]),
            (i = l.split('.')),
            1 === i.length
              ? (Modernizr[i[0]] = n)
              : (!Modernizr[i[0]] ||
                  Modernizr[i[0]] instanceof Boolean ||
                  (Modernizr[i[0]] = new Boolean(Modernizr[i[0]])),
                (Modernizr[i[0]][i[1]] = n)),
            c.push((n ? '' : 'no-') + i.join('-'));
      }
  }
  function a(A) {
    var e = h.className,
      o = Modernizr._config.classPrefix || '';
    if ((R && (e = e.baseVal), Modernizr._config.enableJSClass)) {
      var t = new RegExp('(^|\\s)' + o + 'no-js(\\s|$)');
      e = e.replace(t, '$1' + o + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((e += ' ' + o + A.join(' ' + o)),
      R ? (h.className.baseVal = e) : (h.className = e));
  }
  function l() {
    return 'function' != typeof e.createElement
      ? e.createElement(arguments[0])
      : R
      ? e.createElementNS.call(e, 'http://www.w3.org/2000/svg', arguments[0])
      : e.createElement.apply(e, arguments);
  }
  function i(A, e) {
    if ('object' == typeof A) for (var o in A) d(A, o) && i(o, A[o]);
    else {
      A = A.toLowerCase();
      var t = A.split('.'),
        n = Modernizr[t[0]];
      if ((2 == t.length && (n = n[t[1]]), 'undefined' != typeof n))
        return Modernizr;
      (e = 'function' == typeof e ? e() : e),
        1 == t.length
          ? (Modernizr[t[0]] = e)
          : (!Modernizr[t[0]] ||
              Modernizr[t[0]] instanceof Boolean ||
              (Modernizr[t[0]] = new Boolean(Modernizr[t[0]])),
            (Modernizr[t[0]][t[1]] = e)),
        a([(e && 0 != e ? '' : 'no-') + t.join('-')]),
        Modernizr._trigger(A, e);
    }
    return Modernizr;
  }
  var c = [],
    s = [],
    r = {
      _version: '3.5.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (A, e) {
        var o = this;
        setTimeout(function () {
          e(o[A]);
        }, 0);
      },
      addTest: function (A, e, o) {
        s.push({ name: A, fn: e, options: o });
      },
      addAsyncTest: function (A) {
        s.push({ name: null, fn: A });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = r), (Modernizr = new Modernizr());
  var h = e.documentElement,
    R = 'svg' === h.nodeName.toLowerCase();
  Modernizr.addTest('video', function () {
    var A = l('video'),
      e = !1;
    try {
      (e = !!A.canPlayType),
        e &&
          ((e = new Boolean(e)),
          (e.ogg = A.canPlayType('video/ogg; codecs="theora"').replace(
            /^no$/,
            ''
          )),
          (e.h264 = A.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(
            /^no$/,
            ''
          )),
          (e.webm = A.canPlayType('video/webm; codecs="vp8, vorbis"').replace(
            /^no$/,
            ''
          )),
          (e.vp9 = A.canPlayType('video/webm; codecs="vp9"').replace(
            /^no$/,
            ''
          )),
          (e.hls = A.canPlayType(
            'application/x-mpegURL; codecs="avc1.42E01E"'
          ).replace(/^no$/, '')));
    } catch (o) {}
    return e;
  });
  var d;
  !(function () {
    var A = {}.hasOwnProperty;
    d =
      t(A, 'undefined') || t(A.call, 'undefined')
        ? function (A, e) {
            return e in A && t(A.constructor.prototype[e], 'undefined');
          }
        : function (e, o) {
            return A.call(e, o);
          };
  })(),
    (r._l = {}),
    (r.on = function (A, e) {
      this._l[A] || (this._l[A] = []),
        this._l[A].push(e),
        Modernizr.hasOwnProperty(A) &&
          setTimeout(function () {
            Modernizr._trigger(A, Modernizr[A]);
          }, 0);
    }),
    (r._trigger = function (A, e) {
      if (this._l[A]) {
        var o = this._l[A];
        setTimeout(function () {
          var A, t;
          for (A = 0; A < o.length; A++) (t = o[A])(e);
        }, 0),
          delete this._l[A];
      }
    }),
    Modernizr._q.push(function () {
      r.addTest = i;
    }),
    n(),
    a(c),
    delete r.addTest,
    delete r.addAsyncTest;
  for (var E = 0; E < Modernizr._q.length; E++) Modernizr._q[E]();
  A.Modernizr = Modernizr;
})(window, document);
